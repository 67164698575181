.layout {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  animation: rotate 0.9s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
